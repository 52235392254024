<template>
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="34.47mm" height="20.15mm" viewBox="0 0 97.71 57.12">
        <defs>
            <linearGradient
                id="linear-gradient"
                x1="-4596.21"
                y1="-569.56"
                x2="-4560.1"
                y2="-572.09"
                gradientTransform="translate(-2519.25 -4690.68) rotate(-112.15) scale(1.15 -1.06) skewX(4.71)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" stop-color="#bbe4f9" />
                <stop offset=".1" stop-color="#a8ddf7" stop-opacity=".9" />
                <stop offset=".32" stop-color="#78cef4" stop-opacity=".65" />
                <stop offset=".63" stop-color="#2db4ee" stop-opacity=".24" />
                <stop offset=".81" stop-color="#00a6eb" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="linear-gradient-2"
                x1="-242.38"
                y1="2636.63"
                x2="-256.66"
                y2="2569.45"
                gradientTransform="translate(-2371.01 1276.98) rotate(71.74) scale(1.07 -1.03) skewX(-3.42)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset=".27" stop-color="#00a6eb" />
                <stop offset="1" stop-color="#002e5e" />
            </linearGradient>
            <linearGradient
                id="linear-gradient-3"
                x1="-417.31"
                y1="2404.65"
                x2="-379.58"
                y2="2384.96"
                gradientTransform="translate(-1700.27 1667.62) rotate(71.46) scale(.69 -.91) skewX(-23.85)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" stop-color="#c2e7fa" />
                <stop offset=".06" stop-color="#a3dcf7" stop-opacity=".92" />
                <stop offset=".2" stop-color="#69c9f3" stop-opacity=".77" />
                <stop offset=".32" stop-color="#3bbaef" stop-opacity=".65" />
                <stop offset=".43" stop-color="#1bafed" stop-opacity=".57" />
                <stop offset=".52" stop-color="#07a8eb" stop-opacity=".52" />
                <stop offset=".58" stop-color="#00a6eb" stop-opacity=".5" />
            </linearGradient>
            <linearGradient
                id="linear-gradient-4"
                x1="760.29"
                y1="3287.26"
                x2="744.93"
                y2="3349.11"
                gradientTransform="translate(-2532.75 -1504.04) rotate(122.91) scale(.76 -.9) skewX(-15.36)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" stop-color="#00133a" />
                <stop offset=".47" stop-color="#006096" stop-opacity=".63" />
                <stop offset=".8" stop-color="#00a6eb" stop-opacity=".3" />
            </linearGradient>
        </defs>
        <g>
            <path
                d="M0,52.76h.4v1.87h.04l2.06-1.87h.55l-2.15,1.94,2.27,2.3h-.57l-2.15-2.2h-.04v2.2h-.4v-4.25Z"
                style="fill: #68676c"
            />
            <path d="M4.87,52.76h.4v4.25h-.4v-4.25Z" style="fill: #68676c" />
            <path
                d="M7.63,52.76h.51l2.58,3.72h.01v-3.72h.4v4.25h-.51l-2.59-3.72h-.01v3.72h-.4v-4.25Z"
                style="fill: #68676c"
            />
            <path
                d="M13.49,52.76h.51l2.58,3.72h.01v-3.72h.4v4.25h-.51l-2.58-3.72h-.01v3.72h-.4v-4.25Z"
                style="fill: #68676c"
            />
            <path d="M19.36,52.76h.4v4.25h-.4v-4.25Z" style="fill: #68676c" />
            <path
                d="M22.24,56.23c.21.35.55.53.97.53.53,0,.95-.32.95-.83,0-1.19-2.19-.49-2.19-2.1,0-.75.65-1.16,1.36-1.16.46,0,.87.16,1.16.53l-.34.25c-.2-.28-.46-.42-.83-.42-.51,0-.95.26-.95.8,0,1.28,2.19.51,2.19,2.1,0,.77-.63,1.19-1.32,1.19-.56,0-1.05-.18-1.38-.64l.38-.25Z"
                style="fill: #68676c"
            />
            <path d="M26.06,52.76h.43l1.37,3.72,1.38-3.72h.42l-1.61,4.25h-.4l-1.58-4.25Z" style="fill: #68676c" />
            <path
                d="M32.39,52.76h.41l1.79,4.25h-.44l-.46-1.13h-2.25l-.48,1.13h-.41l1.85-4.25ZM32.57,53.2l-.99,2.33h1.95l-.96-2.33Z"
                style="fill: #68676c"
            />
            <path
                d="M36.4,52.76h1.12c.34,0,1.51,0,1.51,1.12,0,.81-.62,1.03-1.09,1.09l1.24,2.04h-.45l-1.19-2.01h-.74v2.01h-.4v-4.25ZM36.79,54.64h.84c.83,0,1-.46,1-.76s-.16-.75-1-.75h-.84v1.51Z"
                style="fill: #68676c"
            />
            <path
                d="M42.46,52.76h.41l1.79,4.25h-.44l-.46-1.13h-2.25l-.48,1.13h-.41l1.85-4.25ZM42.64,53.2l-.99,2.33h1.95l-.96-2.33Z"
                style="fill: #68676c"
            />
            <path
                d="M49.22,52.76h.4v1.87h.04l2.06-1.87h.55l-2.15,1.94,2.27,2.3h-.57l-2.15-2.2h-.04v2.2h-.4v-4.25Z"
                style="fill: #68676c"
            />
            <path
                d="M56.01,52.66c1.29,0,2.19.94,2.19,2.23s-.9,2.23-2.19,2.23-2.2-.94-2.2-2.23.9-2.23,2.2-2.23ZM56.01,56.76c1.11,0,1.8-.82,1.8-1.87s-.69-1.87-1.8-1.87-1.8.82-1.8,1.87.69,1.87,1.8,1.87Z"
                style="fill: #68676c"
            />
            <path
                d="M60.29,52.76h1.12c.34,0,1.51,0,1.51,1.12,0,.81-.62,1.03-1.08,1.09l1.24,2.04h-.45l-1.19-2.01h-.74v2.01h-.4v-4.25ZM60.69,54.64h.84c.83,0,1-.46,1-.76s-.16-.75-1-.75h-.84v1.51Z"
                style="fill: #68676c"
            />
            <path
                d="M65.05,52.76h1.12c.34,0,1.51,0,1.51,1.12,0,.81-.62,1.03-1.08,1.09l1.24,2.04h-.45l-1.19-2.01h-.74v2.01h-.4v-4.25ZM65.45,54.64h.84c.83,0,1-.46,1-.76s-.16-.75-1-.75h-.84v1.51Z"
                style="fill: #68676c"
            />
            <path
                d="M71.11,52.76h.41l1.79,4.25h-.44l-.46-1.13h-2.25l-.48,1.13h-.41l1.85-4.25ZM71.3,53.2l-.99,2.33h1.95l-.96-2.33Z"
                style="fill: #68676c"
            />
            <path
                d="M75.23,56.23c.21.35.55.53.97.53.53,0,.95-.32.95-.83,0-1.19-2.19-.49-2.19-2.1,0-.75.65-1.16,1.36-1.16.46,0,.87.16,1.16.53l-.34.25c-.2-.28-.46-.42-.83-.42-.51,0-.95.26-.95.8,0,1.28,2.19.51,2.19,2.1,0,.77-.63,1.19-1.32,1.19-.56,0-1.05-.18-1.38-.64l.38-.25Z"
                style="fill: #68676c"
            />
            <path d="M79.66,52.76h.4v1.87h2.38v-1.87h.4v4.25h-.4v-2.01h-2.38v2.01h-.4v-4.25Z" style="fill: #68676c" />
            <path
                d="M87.11,52.66c1.29,0,2.19.94,2.19,2.23s-.9,2.23-2.19,2.23-2.2-.94-2.2-2.23.9-2.23,2.2-2.23ZM87.11,56.76c1.11,0,1.8-.82,1.8-1.87s-.69-1.87-1.8-1.87-1.8.82-1.8,1.87.69,1.87,1.8,1.87Z"
                style="fill: #68676c"
            />
            <path d="M91.39,52.76h.4v4.25h-.4v-4.25Z" style="fill: #68676c" />
            <path
                d="M94.1,52.76h1.31c2.21,0,2.3,1.81,2.3,2.12s-.09,2.12-2.3,2.12h-1.31v-4.25ZM94.49,56.65h.82c1.13,0,2-.55,2-1.76s-.88-1.76-2-1.76h-.82v3.53Z"
                style="fill: #68676c"
            />
        </g>
        <g>
            <path
                d="M39.45,9.2c-10.67,1.72-3.82,18.78,1.45,26.26,5.27,7.49,16.22,11.35,26.89,9.62,10.67-1.72,15.93-8.82,11.75-15.86-4.18-7.04-29.43-21.75-40.09-20.03Z"
                style="fill: url(#linear-gradient)"
            />
            <path
                d="M12.8,21.58C15.37,10.54,63.98-6.31,76.62,2.43c12.65,8.74,1.88,33.97-12.76,40.72-14.64,6.75-53.62-10.52-51.06-21.56Z"
                style="fill: url(#linear-gradient-2)"
            />
            <path
                d="M18.33,34.83C20.78,25.83,59.37-1.47,68.88,1.21c9.51,2.67.07,24.73-11.62,34.2-11.69,9.47-41.38,8.42-38.92-.58Z"
                style="fill: url(#linear-gradient-3)"
            />
            <path
                d="M22.61,2.48c8.56-3.74,50.97,13.42,53.85,23.89,2.87,10.47-19.9,16.51-33.72,12.1C28.92,34.07,14.05,6.22,22.61,2.48Z"
                style="fill: url(#linear-gradient-4)"
            />
            <g>
                <path
                    d="M58.75,10.41c.23,0,.44.1.58.29l.29-.22c-.21-.28-.52-.43-.87-.43-.6,0-1.08.49-1.08,1.08s.49,1.09,1.08,1.09c.35,0,.66-.16.87-.43l-.29-.22c-.14.18-.35.29-.58.29-.4,0-.72-.32-.72-.72s.32-.72.72-.72ZM58.75,9.11c-1.11,0-2.02.91-2.02,2.02s.91,2.02,2.02,2.02,2.02-.91,2.02-2.02-.9-2.02-2.02-2.02ZM58.75,12.79c-.91,0-1.65-.74-1.65-1.65s.74-1.65,1.65-1.65,1.66.74,1.66,1.65-.74,1.65-1.66,1.65Z"
                    style="fill: #fff"
                />
                <path
                    d="M31.39,30.67c-1.83,0-3.35-.42-4.49-1.24l-.45-.32v1.27h-2.57v-5.55h2.57v.31c.12.95.62,1.71,1.48,2.3.84.57,1.83.86,2.97.86s2.13-.21,2.85-.63c.76-.44,1.15-1.08,1.15-1.88s-.33-1.36-.98-1.73c-.59-.34-1.71-.64-3.42-.91-2.39-.4-4.11-1.06-5.12-1.95-.99-.88-1.48-1.99-1.48-3.39s.53-2.56,1.62-3.52c1.08-.95,2.54-1.43,4.33-1.43,1.62,0,3.04.34,4.2,1l.42.24v-.96h2.55v5.34h-2.55v-.89l-.04-.07c-1-1.54-2.46-2.32-4.36-2.32-.97,0-1.79.22-2.43.65-.67.45-1.02,1.07-1.02,1.83s.34,1.35.98,1.65c.57.27,1.75.54,3.6.82,1.7.26,3,.58,3.86.93.83.34,1.53.91,2.08,1.71.55.79.82,1.72.82,2.78,0,1.58-.59,2.8-1.8,3.73-1.21.93-2.82,1.41-4.77,1.41h0Z"
                    style="fill: #fff"
                />
                <path
                    d="M67.79,30.67c-1.83,0-3.34-.42-4.49-1.24l-.45-.32v1.27h-2.57v-5.55h2.57v.31c.12.95.62,1.71,1.48,2.3.83.57,1.83.86,2.97.86s2.13-.21,2.85-.63c.76-.44,1.15-1.08,1.15-1.88s-.33-1.36-.98-1.73c-.59-.34-1.71-.64-3.42-.91-2.39-.4-4.11-1.06-5.12-1.95-.99-.88-1.48-1.99-1.48-3.39s.53-2.56,1.62-3.52c1.08-.95,2.54-1.43,4.32-1.43,1.62,0,3.04.34,4.21,1l.42.24v-.96h2.55v5.34h-2.55v-.89l-.04-.07c-1-1.54-2.46-2.32-4.36-2.32-.97,0-1.79.22-2.43.65-.68.45-1.02,1.07-1.02,1.83s.34,1.35.98,1.65c.58.27,1.75.54,3.6.82,1.7.26,3,.58,3.86.93.83.34,1.53.91,2.07,1.71.55.79.83,1.72.83,2.78,0,1.58-.59,2.8-1.8,3.73-1.21.93-2.82,1.41-4.77,1.41h0Z"
                    style="fill: #fff"
                />
                <path
                    d="M56.8,15.33c-1.58-1.65-3.6-2.49-6.01-2.49s-4.44,1-6.12,2.97l-.5.58v-3.28h-5.94v2.55h3v19.58h2.94v-7.48l.48.48c1.75,1.75,3.79,2.64,6.06,2.64s4.16-.82,5.88-2.43c1.71-1.61,2.58-3.83,2.58-6.62s-.8-4.85-2.38-6.51ZM54.38,26.51c-1.14,1.17-2.6,1.76-4.33,1.76-1.24,0-2.34-.32-3.27-.96-.92-.63-1.61-1.43-2.03-2.37-.42-.93-.63-1.92-.63-2.93,0-1.98.62-3.6,1.84-4.81,1.22-1.21,2.66-1.82,4.28-1.82,1.52,0,2.89.56,4.07,1.68,1.19,1.12,1.79,2.69,1.79,4.66s-.58,3.63-1.71,4.8Z"
                    style="fill: #fff"
                />
                <rect x="44.17" y="35.26" width="2.94" height="2.94" style="fill: #fff" />
            </g>
        </g>
    </svg>
</template>
